<template>
  <v-container>
    <v-card class="pa-2">
      <v-card-title> Due Payments </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-data-table
            show-select
            :items="payable_fees"
            :headers="headers"
            v-model="selected_fees"
          >
            <template v-slot:action="{ item }">
              <v-btn icon>
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table></v-col
        >
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row justify="end">
        <v-col cols="12" md="5" order="last">
          <p class="">
            Due Fee:
            <strong>{{ total_amount.toFixed(2) }} Taka</strong>
          </p>
          <p class="">
            Bank Fee:
            <strong>{{ bank_fee.toFixed(2) }} Taka</strong>
          </p>
          <p class="">
            Total Payable:
            <strong
              >{{
                (Number(this.total_amount) + Number(this.bank_fee)).toFixed(2)
              }}
              Taka</strong
            >
          </p>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          width="100%"
          color="primary"
          @click="pay"
          :disabled="selected_fees.length < 1"
          >Pay Now</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      payable_fees: [],
      selected_fees: [],
      payment_url: "",
      headers: [
        { text: "Title", value: "room_fee.fee.name" },
        { text: "Amount", value: "room_fee.fee.amount" },
        { text: "Month", value: "room_fee.fee_month" },
        { text: "Year", value: "room_fee.year" },
        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    total_amount() {
      return this.selected_fees.reduce((acc, fee) => {
        return Number(acc) + Number(fee.room_fee.fee.amount);
      }, Number(0));
    },
    bank_fee() {
      return (this.total_amount * 1.5) / 100;
    },
  },
  mounted() {
    this.getPayableFees();
  },
  methods: {
    getPayableFees() {
      return this.$api
        .get("/payments/room-user-fees/payable/")
        .then((result) => {
          this.payable_fees = result.data;
        })
        .catch(() => {});
    },
    pay() {
      const room_user_fees = [];
      this.selected_fees.forEach((selected_fee) =>
        room_user_fees.push(selected_fee.id)
      );
      return this.$api
        .post("payments/room-user-fees/pay/", {
          room_user_fee: room_user_fees,
        })
        .then((res) => {
          console.log(res.data.payment_url);
          const popup = window.open(
            res.data.payment_url,
            "payment",
            "width=600,height=700,top=1200,left=650, toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,"
          );
          const interval = setInterval(() => {
            if (popup.closed) {
              clearInterval(interval);
              this.getPayableFees();
              this.$store.dispatch("payment/fetchPaymentHistory");
            }
          }, 1000);
        });
    },
  },
};
</script>

<style>
</style>
